<template>
  <!-- Table Container Card -->
  <div class="row flex-column px-2 table-resize">
    <div class="row mb-1 search-filter">
      <div class="col-md-4 col-sm-12 d-flex align-content-end justify-content-end">
        <b-form-input :value="searchQuery"
                      :placeholder="$t('document.searchOnArchive')"
                      v-on:keyup.enter="updateQueryWithSearchText"/>
      </div>
      <div class="justify-content-end col-md-8 col-sm-12 align-content-end align-items-end d-flex">
        <b-button
            @click="clickFileInput"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-sm-block small elevated-btn px-2"
            variant="primary">
          {{ $t('document.addDocument') }}
        </b-button>
        <b-form-file
            multiple
            @change="$emit('handleFiles', $event)"
            accept=".pdf, .jpg, .png, .gif, .xls, .doc, .docx"
            id="uploadFiles"
            class="hidden"/>
      </div>
    </div>
    <div class="row">
      <div class=" w-100">
        <b-table
            ref="refDocumentListTable"
            :items="documents"
            :small="false"
            responsive
            @row-hovered="onRowHovered"
            :fields="tableColumns"
            primary-key="id"
            :hover="true"
            :head-variant="null"
            :sort-by.sync="sortBy"
            :busy="isBusy"
            show-empty
            :empty-text="$t('document.noDocumentFoundForCriteria')"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative documents-table">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>

          <template #head(code)>
            <feather-icon icon="HashIcon"
                          class="mx-auto"/>
          </template>
          <template #head(title)>
            <span>{{ $t('table.document') }}</span>
          </template>
          <template #head(status)>
            <span>{{ $t('table.status') }}</span>
          </template>
          <template #head(documentType)>
            <span>{{ $t('table.type') }}</span>
          </template>
          <template #head(archivedAt)>
            <span>{{ $t('table.archivedAt') }}</span>
          </template>
          <template #head(actions)>
            <span>{{ $t('table.actions') }}</span>
          </template>

          <!-- Column: code -->
          <template #cell(code)="data">
            <span class="p-25 rounded font-weight-bold" style="background-color: rgba(9, 30, 66, 0.04);">#{{
                data.value
              }}</span>
          </template>

          <template #cell(title)="data">
            <div>
              <div class="row">
                <span class="font-weight-bolder text-black">{{ data.value }}</span>
              </div>
              <div class="row">
                <span class="text-black-50 font-weight-bolder font-small-3">{{ data.item.workspace }}</span>
              </div>
            </div>
          </template>

          <template #cell(documentType)="data">
            <b-badge pill
                     :variant="`light-${resolveDocumentTypeVariant(data.item.documentType.displayText)}`"
                     class="text-capitalize">
              {{ $t('documentTypes.'.concat(data.item.documentType.name)) }}
            </b-badge>
          </template>

          <template #cell(status)="data">
            <b-avatar :id="`document-row-${data.item.id}`"
                      size="32"
                      :variant="`light-${resolveDocumentStatusVariantAndIcon(data.item.lifecycleDetail.status.name).variant}`">
              <feather-icon :icon="resolveDocumentStatusVariantAndIcon(data.item.lifecycleDetail.status.name).icon"/>
            </b-avatar>
            <b-tooltip :target="`document-row-${data.item.id}`"
                       placement="top">
              <p class="mb-0">
                {{ $t('documentStates.'.concat(data.item.lifecycleDetail.status.name)) }}
              </p>
            </b-tooltip>

          </template>

          <template #cell(archivedAt)="data">

          <span v-b-tooltip.hover.bottom="getFormattedDate(data.item.archivedAt)">
            {{ getFromNow(data.item.archivedAt) }}
          </span>

          </template>

          <template #cell(actions)="data">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      class="rounded-circle"
                      variant="flat-secondary"
                      v-if="data.item.archiveFile !== null"
                      style="padding: 5px; background-color:rgba(9, 30, 66, 0.04);"
                      @click="downloadArchivedDocument(data.item.archiveFile)"
                      v-b-tooltip.hover.top="$t('table.downloadArchivedDocument')">
              <div style="height: 17px; width: 17px">
                <feather-icon icon="DownloadIcon"/>
              </div>
            </b-button>

          </template>

        </b-table>
      </div>
    </div>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->

          <span class="text-muted">{{
              $tc('itemCountOnTable', dataMeta.from, dataMeta.to, {
                from: dataMeta.from,
                to: dataMeta.to
              })
            }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
              v-on:change="$emit('page-updated',$event)"
              v-model="currentPage"
              :total-rows="totalDocuments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormFile,
  BFormInput,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBPopover,
  VBTooltip
} from "bootstrap-vue";
import DateUtil from '@/services/DateUtil'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";

export default {
  name: "ArchivedDocumentList",
  directives: {
    'b-popover': VBPopover,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BFormInput,
    BButton,
    BSpinner,
    BFormFile,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
  },
  data() {
    return {
      searchQuery: null,
      currentPage: 1,
      // perPage: 10,
      titleSearchQueryParam: "",
      codeSearchQueryParam: "",
      dateRangeQueryParam: [],
      dateRangeStartQueryParam: null,
      dateRangeEndQueryParam: null,
      documentTypeQueryParam: null,
      documentStatusQueryParam: null,
      labelSearchQueryParam: null
    }
  },
  mounted() {
    this.searchQuery = this.$route.query.q
    this.loadDocumentTypes()
    this.loadDocumentStatuses()
  },
  props: {
    isBusy: Boolean,
    documents: Array,
    // currentPage: Number,
    perPage: Number,
    totalDocuments: Number,
    tableColumns: Array,
    perPageOptions: Array,
    sortBy: String,
    isSortDirDesc: Boolean,
    fetch: Function,
    dataMeta: Object,
    resolveDocumentStatusVariantAndIcon: Function,
    resolveDocumentTypeVariant: Function,
  },
  computed: {
    getNewDocumentUrl() {
      return this.newDocumentUrl;
    },
    documentTypes() {
      return this.$store.getters['document/getDocumentTypes']
    },
    documentStatuses() {
      return this.$store.getters['document/getDocumentStatuses']
    },
    routeQuery() {
      return this.$route.query.q
    }
  },
  methods: {
    openNewDocument() {
      this.$router.replace(this.newDocumentUrl)
      this.cleanStates()
    },
    fetchDocuments() {
      this.$store.dispatch('document/loadArchivedDocuments', {q: this.searchQuery})
    },
    updateQueryWithSearchText(event) {
      console.log(this.$route.query)
      const currentRouteQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (event.target.value) currentRouteQuery.q = event.target.value
      else delete currentRouteQuery.q

      this.$router.replace({name: this.$route.name, query: currentRouteQuery})
          .catch(error => {
          })
    },
    cleanStates() {
      this.$store.dispatch('document/cleanVariables')
      this.$store.dispatch('document/refreshDocument')
    },
    clickFileInput() {
      document.getElementById('uploadFiles').click()
    },
    getFromNow(date) {
      return DateUtil.fromNow(date);
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    async downloadArchivedDocument(id) {
      const fileUrl = await this.$store.dispatch('document/downloadArchivedFile', id)

      if (fileUrl !== undefined) {
        window.open(fileUrl)
      }
    },
    onRowHovered(event) {
      event.i
    },
    showMsgBoxTwo(documentId) {
      this.$bvModal
          .msgBoxConfirm(this.$i18n.t('messages.document.questionBeforeArchive'), {
            title: i18n.t('messages.areYouSure'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: i18n.t('yes'),
            cancelTitle: i18n.t('exit'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(accepted => {
            if (accepted) {
              let payload = {
                documentId: documentId
              }

              this.$store.dispatch('document/delete', payload)
                  .then(() => this.$store.dispatch('document/loadArchivedDocuments'))
                  .then(() =>
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: i18n.t('messages.document.deleted'),
                          icon: 'EditIcon',
                          variant: 'success',
                        },
                      }, {position: 'top-center'}))
                  .catch(() =>
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: i18n.t('messages.errorOccurred'),
                          icon: 'AlertTriangeIcon',
                          variant: 'danger'
                        },
                      }, {position: 'top-center'})
                  )
            }
          })
    },
    loadDocumentStatuses() {
      this.$store.dispatch('document/getDocumentStatuses')
    },
    loadDocumentTypes() {
      this.$store.dispatch('document/getDocumentTypes')
    },
    filterByDate(event) {
      this.$emit('toggle-filter', true)
      this.dateRangeStartQueryParam = event[0]
      this.dateRangeEndQueryParam = event[1]

      this.updateDocumentQuery()
    },
    updateDocumentQuery() {
      this.$emit('toggle-filter', true)
      const payload = {
        code: this.codeSearchQueryParam,
        documentTitle: this.titleSearchQueryParam,
        paging: {
          page: this.currentPage,
          size: this.perPage
        }
      }

      if (this.labelSearchQueryParam !== null && this.labelSearchQueryParam !== "") {
        payload.labels = [
          this.labelSearchQueryParam
        ]
      }

      if (this.documentStatusQueryParam !== null) {
        payload.documentStatuses = [
          this.documentStatusQueryParam.name
        ]
      }

      if (this.documentTypeQueryParam !== null) {
        payload.documentTypes = [
          this.documentTypeQueryParam.name
        ]
      }

      if (this.dateRangeStartQueryParam !== null || this.dateRangeEndQueryParam !== null) {
        payload.createdAt = {
          start: DateUtil.getFormattedDate2(this.dateRangeStartQueryParam),
          end: DateUtil.getFormattedDate2(this.dateRangeEndQueryParam)
        }
      }

      this.$store.dispatch('document/queryDocuments', payload)
    },
    cleanFilterParameters() {
      this.$emit('toggle-filter', false)
      this.currentPage = 1
      this.perPage = 10
      this.titleSearchQueryParam = ""
      this.codeSearchQueryParam = ""
      this.documentTypeQueryParam = null
      this.documentStatusQueryParam = null

      this.$store.dispatch('document/loadArchivedDocuments')
    }
  }
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.documents-table {
	tbody tr td {
		span {
			@media screen and (max-width: 1550px) {
				font-size: 14px;
			}
		}
		.badge {
			font-size: 12px;
		}
	}
}

.table-resize {
	@media screen and (max-width: 900px) {
		overflow: auto;
		.search-filter {
			width: 100%;
			> .col-md-4 {
				margin-bottom: 15px;
			}
		}
		.documents-table {
			min-width: 1000px;
		}
	}
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
