import {computed, ref, watch} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {useRouter} from "@core/utils/utils";

export default function useDocumentList() {
  // Use toast
  const toast = useToast()
  const {route, router} = useRouter()

  const refDocumentListTable = ref(null)
  const routeParams = computed(() => route.value.params)
  watch(routeParams, () => {
    // eslint-disable-next-line no-use-before-define

  })
  // Table Handlers
  const tableColumns = [
    {key: 'code', label: '#', sortable: true},
    {key: 'title', sortable: true},
    {key: 'documentType', sortable: true, formatter: val => `$${val}`},
    {key: 'archivedAt', sortable: true},
    {key: 'actions', sortable: false},
  ]
  const perPage = ref(20)
  const from = ref(0)
  const to = ref(0)
  const totalDocuments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const routeQuery = computed(() => route.value.query.q)
  watch(routeQuery, val => {
    searchQuery.value = val
    console.log(val)
  })
  const searchQuery = ref(routeQuery.value)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value ? refDocumentListTable.value.localItems.length : 0
    return {
      from: from.value,
      to: to.value > totalDocuments.value ? totalDocuments.value : to.value,
      of: totalDocuments.value,
    }
  })

  const reFetchData = () => {
    refDocumentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    fetchArchivedDocuments()
  })

  const fetchArchivedDocuments = (ctx, callback) => {
    store
      .dispatch('document/loadArchivedDocuments', {
        q: searchQuery.value == undefined ? "" : searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const {results, total, start, end} = response
        from.value = start
        to.value = end
        totalDocuments.value = total
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching documents' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        }, {position: 'top-center'})
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDocumentStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return {variant: 'warning', icon: 'PieChartIcon'}
    if (status === 'Paid') return {variant: 'success', icon: 'CheckCircleIcon'}
    if (status === 'Downloaded') return {variant: 'info', icon: 'ArrowDownCircleIcon'}
    if (status === 'NEW') return {variant: 'secondary', icon: 'SaveIcon'}
    if (status === 'Sent') return {variant: 'secondary', icon: 'SendIcon'}
    if (status === 'Past Due') return {variant: 'danger', icon: 'InfoIcon'}
    return {variant: 'secondary', icon: 'XIcon'}
  }

  const resolveDocumentTypeVariant = type => {
    if (type === '' || type === null) return 'warning'
    return 'info'
  }

  return {
    fetchArchivedDocuments,
    tableColumns,
    perPage,
    currentPage,
    totalDocuments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,

    statusFilter,

    resolveDocumentStatusVariantAndIcon,
    resolveDocumentTypeVariant,

    reFetchData,
  }
}
