<template>
  <div>
    <div>
      <Empty v-if=" documentList.total === 0 && toBeUploadedFiles.length === 0"
             @handleFiles="handleFiles"/>

      <WaitingForUploadList
          v-if="toBeUploadedFiles.length > 0"
          class="mb-2"
          :waiting-files="toBeUploadedFiles"
          @fetch="fetchArchivedDocuments"
          @compose="reFetchData">
      </WaitingForUploadList>

      <ArchivedDocumentList
          v-if="documentList.total > 0 && toBeUploadedFiles.length === 0"
          @handleFiles="handleFiles"
          :is-busy="loading"
          :per-page="perPage"
          :per-page-options="perPageOptions"
          :documents="documentList.results"
          :total-documents="totalDocuments"
          :fetch="fetchArchivedDocuments"
          :table-columns="tableColumns"
          :data-meta="dataMeta"
          @toggle-filter="toggleFilter"
          :resolve-document-type-variant="resolveDocumentTypeVariant"
          :resolve-document-status-variant-and-icon="resolveDocumentStatusVariantAndIcon"
          @page-updated="setCurrentPage($event)">
      </ArchivedDocumentList>
    </div>
  </div>
</template>

<script>
import Empty from "@/views/Documents/Archive/List/Empty";
import ArchivedDocumentList from "@/views/Documents/Archive/List/ArchivedDocumentList"
import WaitingForUploadList from "@/views/Documents/Archive/Upload/WaitingForUploadList";
import {mapState} from "vuex";
import {avatarText} from "@core/utils/filter";
import useArchivedDocumentList from "@/views/Documents/Archive/List/useArchivedDocumentList";
import {uuid} from "vue-uuid";
import {MiscUtils} from "@/services/Utils";

export default {
  name: "List",
  components: {Empty, ArchivedDocumentList, WaitingForUploadList},
  computed: {
    ...mapState({
      documentList: state => state.document.archivedDocumentList,
      toBeUploadedFiles: state => state.document.toBeUploadedFileList,
      loading: state => state.document.listLoading
    }),
  },
  data() {
    return {
      globalUploadProgress: {
        status: 'not_started', // in_progress, done
        ignoreAllFiles: false,
        percentage: 0,
        color: 'primary',
        currentUploadingFileItem: {
          fileName: ''
        }
      },
    }
  },
  setup() {
    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchArchivedDocuments,
      getNewDocumentUrl,
      tableColumns,
      perPage,
      currentPage,
      totalDocuments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDocumentListTable,
      statusFilter,
      reFetchData,
      resolveDocumentStatusVariantAndIcon,
      resolveDocumentTypeVariant,
    } = useArchivedDocumentList()

    return {
      fetchArchivedDocuments,
      getNewDocumentUrl,
      tableColumns,
      perPage,
      currentPage,
      totalDocuments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDocumentListTable,
      statusFilter,
      reFetchData,
      statusOptions,
      avatarText,
      resolveDocumentStatusVariantAndIcon,
      resolveDocumentTypeVariant,
    }
  },
  created() {
  },
  mounted() {
    this.fetchArchivedDocuments()
  },
  methods: {
    setCurrentPage(currentPage) {
      this.currentPage = currentPage
    },
    toggleFilter(event) {
      this.isFilterEnabled = event
    },
    unshiftFileAsToBeUploaded(fileItem) {
      this.$store.commit('document/UNSHIFT_FILE_ITEM_IN_ARCHIVED_LIST', fileItem)
    },
    handleFiles(e) {
      console.log(e)
      if (this.globalUploadProgress.ignoreAllFiles) {
        this.resetGlobalProgress()
      }
      const files = e.target.files;
      if (!files || files.length === 0) return;
      //this.$refs["fileInput"].value = null; // fix can't select the same excel

      for (let i = 0; i < files.length; i++) {
        let fileItem = {
          contractType: {
            text: '',
            value: ''
          },
          uid: uuid.v4(),
          file: files[i],
          name: files[i].name,
          type: files[i].type,
          size: MiscUtils.formatBytes(files[i].size, 2),
          percentage: 0,
          status: 'wait_for_upload'
        }
        this.unshiftFileAsToBeUploaded(fileItem)
      }
    },
    resetGlobalProgress() {
      this.globalUploadProgress = {
        status: 'not_started',
        latestUpload: false,
        percentage: 0,
        color: 'primary',
        currentUploadingFileItem: {
          fileName: ''
        }
      }
    },

  }
}
</script>

<style scoped>

</style>
