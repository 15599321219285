<template>
  <div class="row d-flex flex-column px-2">

    <div class="mb-1 row d-flex align-items-center justify-content-between">
      <div class="justify-content-center align-content-center align-items-center d-flex">
        <b-form-checkbox v-model="allSelected"
                         class="mr-1"
                         @change="toggleCheckAll">
        </b-form-checkbox>
        <b-button
            @click="openWorkspaceSelection"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-sm-block mr-1  elevated-btn small px-1"
            variant="success">
          <feather-icon
              class="p-0"
              :icon="'UploadCloudIcon'"
              size="15"
          />
          <span class="ml-50">Yüklemeyi başlat</span>
        </b-button>
        <b-button
            @click="clickFileInput"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-sm-block mr-1 small elevated-btn px-2"
            variant="primary">
          {{$t('document.addDocument')}}
        </b-button>
        <b-button v-if="selectedFiles.length > 0" style=" background-color: rgba(98, 98, 98, 0.104)"
                  v-ripple.400="'rgba(98, 98, 98, 0.104)'"
                  variant="flat-black"
                  class="rounded elevated-btn small"
                  @click="removeSelectedFiles">
          Seçilenleri kaldır
        </b-button>
      </div>
      <b-form-file
          multiple
          @change="$emit('handleFiles', $event)"
          accept=".pdf, .jpg, .png, .gif, .xls, .doc, .docx"
          id="uploadFiles"
          :file-name-formatter="formatNames"
          class="hidden"/>
    </div>
    <!-- Table Container Card -->
    <div class="row">
      <div class=" w-100">
        <b-table
            ref="refWaitingFiles"
            selectable
            responsive="sm"
            :select-mode="'multi'"
            :items="waitingFiles"
            :small="false"
            @row-selected="onFileSelected"
            :fields="tableColumns"
            primary-key="uid"
            :hover="true"
            :head-variant="null"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative p-0 m-0 w-100">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>

          <template #head(name)>
            <span>Belge</span>
          </template>
          <template #head(type)>
            <span>Belge tipi</span>
          </template>
          <template #head(percentage)>
            <span>Yüklenme</span>
          </template>
          <template #cell(name)="data">
            <div>
              <div class="row">
                <span class="font-weight-bolder text-black">{{ data.value }}</span>
              </div>
              <div class="row">
                <span class="text-black-50 font-weight-bolder font-small-3">{{ data.item.workspace }}</span>
              </div>
            </div>
          </template>

          <template #cell(type)="data">
        <span v-b-tooltip.hover.bottom="data.value" class="d-inline-block text-truncate"
              style="max-width: 150px;">{{ data.value }}</span>
          </template>

          <template #cell(percentage)="data">
            <b-progress
                :max="100"
                :key="data.item.uid"
                height="1.4rem"
                :value="`${data.value}`"
                :variant="data.value === 100 ? 'success' : 'danger'"
                :class="'progress-bar-' + (data.value === 100) ? 'success' : 'danger'">
              <b-progress-bar
                  :value="`${data.value}`"
                  :label="`${((data.value / 100) * 100).toFixed(2)}%`"
                  :variant="data.value === 100 ? 'success' : 'danger'"
              />
            </b-progress>
          </template>
        </b-table>
      </div>
    </div>
    <select-workspace
        v-if="selectWorkspaceEnabled"
        @close="selectWorkspaceEnabled = false"
        @start="startUploading"
        @onSelect="selectedWorkspace = $event.workspace"
        :description="$t('document.documentRequiresWorkspace')"/>
  </div>
</template>

<script>
import {BButton, BFormCheckbox, BFormFile, BProgress, BProgressBar, BTable, VBPopover, VBTooltip} from "bootstrap-vue";
import DateUtil from '@/services/DateUtil'
import Ripple from "vue-ripple-directive";
import SelectWorkspace from "@/views/Documents/Archive/Upload/SelectWorkspace";
import {PEventBus} from "@/services/PEventBus";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "ArchivedDocumentList",
  directives: {
    'b-popover': VBPopover,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable,
    BFormFile,
    BButton,
    BProgress,
    BProgressBar,
    BFormCheckbox,
    SelectWorkspace
  },
  data() {
    return {
      selectWorkspaceEnabled: false,
      selectedWorkspace: null,
      allSelected: false,
      selectedFiles: [],
      currentPage: 1,
      perPage: 10,
      tableColumns: [
        {key: 'name', sortable: true},
        {key: 'type', sortable: true},
        {key: 'size', sortable: true},
        {key: 'percentage', sortable: true,},
      ]
    }
  },
  mounted() {
    PEventBus.$on('onFileUploadProgress', this.handleProgress)
  },
  beforeDestroy() {
    PEventBus.$off('onFileUploadProgress', this.handleProgress)
  },
  props: {
    isBusy: Boolean,
    waitingFiles: Array,
    totalDocuments: Number,
    perPageOptions: Array,
    workspaceId: String,
    sortBy: String,
    isSortDirDesc: Boolean,
    fetch: Function,
    dataMeta: Object,
    resolveDocumentStatusVariantAndIcon: Function,
    resolveDocumentTypeVariant: Function,
  },
  computed: {
    getNewDocumentUrl() {
      return this.newDocumentUrl;
    },
    documentTypes() {
      return this.$store.getters['document/getDocumentTypes']
    },
    documentStatuses() {
      return this.$store.getters['document/getDocumentStatuses']
    },
  },
  methods: {
    clickFileInput() {
      document.getElementById('uploadFiles').click()
    },
    handleProgress(event) {
      this.$store.commit('document/UPDATE_FILE_UPLOAD_PROGRESS', event)
    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`
    },
    toggleCheckAll() {
      if (!this.allSelected) {
        this.clearSelection()
      } else {
        this.selectAll()
      }
    },
    openWorkspaceSelection() {
      this.selectWorkspaceEnabled = true
    },
    startUploading(workspaceId) {
      let processedFilesCount = 0
      let successfulUploadedFilesCount = 0
      this.waitingFiles.forEach(value => {
        this.$store.dispatch('document/upload', {
          id: value.uid,
          documentTitle: value.name,
          file: value.file,
          workspaceId: workspaceId
        }).then((res) => {
          successfulUploadedFilesCount++
          processedFilesCount++
          if (successfulUploadedFilesCount === this.waitingFiles.length) {
            // omitted
            this.finishArchiving(`${successfulUploadedFilesCount} adet belge başarıyla aktarıldı`)
          } else if (processedFilesCount === this.waitingFiles.length) {
            this.finishArchiving(`${successfulUploadedFilesCount} adet belge başarıyla aktarıldı. ${processedFilesCount - successfulUploadedFilesCount} adet belge aktarılamadı.`)
          }
        }).catch((error) => {
          if (error.errorCode === 9010) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('messages.document.requiresGroupRelation'),
                icon: 'AlertTriangeIcon',
                variant: 'danger'
              },
            }, {position: 'top-center'})

            this.$router.push({name: 'organizations'})
          } else {
            processedFilesCount++
            if (processedFilesCount === this.waitingFiles.length) {
              this.finishArchiving(`${successfulUploadedFilesCount} adet belge başarıyla aktarıldı. ${processedFilesCount - successfulUploadedFilesCount} adet belge aktarılamadı.`)
            }
          }
        })
      })
    },
    finishArchiving(message) {
      this.$bvModal
          .msgBoxConfirm(message, {
            title: 'Aktarım tamamlandı',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Devam et',
            cancelTitle: i18n.t('exit'),
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: false,
          })
          .then(value => {
            if (value) {
              this.removeAllSucceeded()
              this.fetchArchiveList()
              // this.$emit("fetch")
              this.$emit("compose")
            }
          })
    },
    fetchArchiveList() {
      this.$emit("fetch")
    },
    onFileSelected(rows) {
      console.log(rows)
      rows.forEach(row => {
        const index = this.selectedFiles.findIndex(value => value.uid === row.uid)
        console.log(row)
        if (index >= 0) {
          this.unselectRow(index)
          this.selectedFiles.splice(index, 1)
        } else {
          this.selectRow(index)
          this.selectedFiles.push(row)
        }
      })
    },
    removeFromToBeUploaded(file) {
      this.$store.commit('document/REMOVE_FILE_ITEM_IN_ARCHIVED_LIST', file)
    },
    removeAllSucceeded() {
      this.$store.commit('document/REMOVE_SUCCESSFUL_UPLOADED_FILES')
    },
    removeSelectedFiles() {
      this.selectedFiles.forEach((file) => {
        this.removeFromToBeUploaded(file)
      })
    },
    clearSelection() {
      this.selectedFiles = []
      this.$refs.refWaitingFiles.clearSelected()
      console.log(this.selectedFiles)
    },
    selectAll() {
      this.$refs.refWaitingFiles.selectAllRows()
    },
    selectRow(index) {
      this.$refs.refWaitingFiles.selectRow(index)
    },
    unselectRow(index) {
      this.$refs.refWaitingFiles.unselectRow(index)
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    filterByDate(event) {
      this.$emit('toggle-filter', true)
      this.dateRangeStartQueryParam = event[0]
      this.dateRangeEndQueryParam = event[1]

      this.updateDocumentQuery()
    },
  }
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
