<template>
  <div>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <b-img class="w-25 mb-3"
                 fluid
                 :src="imgUrl"
                 alt="Not authorized page"
          />
          <h2 class="mb-2 font-weight-bold">
            {{ $t('notExistsArchivedDocument') }}
          </h2>
          <p class="mb-1 font-medium-2" style="margin-left: 20%; margin-right: 20%">
            {{ $t('document.archiveDescription') }}
          </p>
          <b-form-file
              multiple
              @change="$emit('handleFiles', $event)"
              accept=".pdf, .jpg, .png, .gif, .xls, .doc, .docx"
              id="uploadFiles"
              :file-name-formatter="formatNames"
              class="hidden"/>
          <b-button
              @click="clickFileInput"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mb-1 btn-sm-block  elevated-btn normal px-3"
              variant="primary">
            {{ $t('document.addDocument') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {BImg, BButton, BFormFile} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'

export default {
  name: "Empty",
  components: {
    BImg,
    BFormFile,
    BButton
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalShow: false,
      emptyImg: require('@/assets/images/illustration/empty.svg'),
    }
  },
  methods: {
    clickFileInput() {
      document.getElementById('uploadFiles').click()
    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`
    }
  },
  computed: {
    imgUrl() {
      return this.emptyImg
    },
  }
}
</script>

<style scoped>

</style>
