<template>
  <div>
    <b-modal no-close-on-backdrop
             ref="select-workspace-modal"
             lazy
             hide-footer
             @hidden="hideWorkspaceSelect"
             :title="$t('document.chooseWorkspace')">

      <b-row align-h="start">
        <b-col class="mb-1">
          <h5>{{ description }}</h5>
        </b-col>
      </b-row>
      <b-row align-h="start" class="w-100">
        <b-col>
          <v-select v-model="selectedWorkspace"
                    :options="workspaces"
                    label="name"
                    class="invoice-filter-select"
                    :placeholder="$t('select.workspaces')">
            <template #selected-option="{ name }">
                      <span class="text-truncate overflow-hidden">
                        {{ name }}
                      </span>
            </template>
          </v-select>
        </b-col>
      </b-row>

      <b-row align-h="start" class="mt-2 w-100">
        <b-col cols="12" class="justify-content-end align-content-end d-flex  w-100">
          <b-button
              @click="submitSelection"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="selectedWorkspace === null">
            <b-spinner small v-if="submitting"/>
            <span v-else>Devam et</span>
          </b-button>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, BCol, BForm, BFormGroup, BFormInput, BRow, BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";

export default {
  name: "WorkspaceSelectionModal",
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BModal,
    vSelect,
  },
  props: {
    submitting: Boolean,
    description: String,
    updateOperation: Boolean,
    willUpdatedOnChange: Boolean,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      populateTemplateVariables: true,
      isDefault: false,
      defaultDocumentName: '',
      selectedDocumentType: null,
      selectedWorkspace: null
    }
  },
  computed: {
    ...mapState({
      workspaces: state => state.workspaces.myWorkspaces,
    }),
  },
  mounted() {
    let defaultWorkspaceId = localStorage.getItem('defaultWorkspaceId')
    console.log(defaultWorkspaceId)
    if (defaultWorkspaceId !== null) {
      this.selectedWorkspace = this.workspaces.find(value => value.organizationId === defaultWorkspaceId)
    }
    this.showModal()
  },
  beforeDestroy() {
    this.$refs['select-workspace-modal'].hide()
  },
  methods: {
    showModal() {
      this.$refs['select-workspace-modal'].show()
    },
    submitSelection() {
      this.$emit('start', this.selectedWorkspace.organizationId)
      this.hideWorkspaceSelect()
    },
    hideWorkspaceSelect() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
